import * as React from 'react';
import { useIntl } from 'apps/freshbuffer/src/core/Intl';
import { Button, Snackbar, Alert } from '@mui/material';

import { useMediaIsDesktop } from 'apps/freshbuffer/src/utilities/Media/useMediaIsDesktop';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { getSocialMediaShareUrls } from './getSocialmediaUrl'
import Facebook from './images/facebook.svg';
import Messenger from './images/messenger.svg';
import Whatsapp from './images/whatsapp.svg';
import styles from './SocialMediaControls.module.css';

type SocialMediaControlsProps = {
  emailSubject: string;
  emailBody: string;
};

export const SocialMediaControls = ({
  emailSubject,
  emailBody,
}: SocialMediaControlsProps) => {
  const { T } = useIntl();
  const [openToastMessage, setOpenToastMessage] = React.useState(false);
  const copyToClipboard = React.useCallback((url: string) => {
    navigator.clipboard.writeText(url);
    setOpenToastMessage(true);
  }, []);
  const isDesktop = useMediaIsDesktop();
  const {
    facebookShareUrl,
    fbMessengerShareUrl,
    whatsAppShareUrl,
    copyLinkUrl,
    emailShareUrl,
  } = getSocialMediaShareUrls(
    'https://www.freshbuffer.com',
    !isDesktop,
    emailSubject,
    emailBody,
  );

  return (
    <div className={styles.root}>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={facebookShareUrl}
        startIcon={<Facebook viewBox="0 0 20 20" />}
      >
        {T('LBL_FACEBOOK')}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={fbMessengerShareUrl}
        startIcon={<Messenger viewBox="0 0 20 20" />}
      >
        {T('LBL_MESSENGER')}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={whatsAppShareUrl}
        startIcon={<Whatsapp viewBox="0 0 20 20" />}
      >
        {T('LBL_WHATSAPP')}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        startIcon={<InsertLinkOutlinedIcon />}
        onClick={() => copyToClipboard(copyLinkUrl)}
      >
        {T('LBL_LINKEDIN')}
      </Button>
      <Button
        className={styles.leftAlignedButton}
        fullWidth
        value={emailShareUrl}
        startIcon={<EmailOutlinedIcon />}
      >
        {T('LBL_REGISTER_EMAIL')}
      </Button>
   
      <Snackbar open={openToastMessage} autoHideDuration={6000}>
        <Alert severity="success">{T('lbl.toast.success.link_copied')}</Alert>
      </Snackbar>
    </div>
  );
};
