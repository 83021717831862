import { ROUTES } from "@freshbuffer/core/Routing";
import BurgerIcon from "@freshbuffer/icons/Burger.svg";
import CrossIcon from "@freshbuffer/icons/Cross.svg";
import ENFlagIcon from "@freshbuffer/icons/ENFlag.svg";
import NLFlagIcon from "@freshbuffer/icons/NLFlag.svg";
import { Button, Drawer, IconButton } from "@mui/material";
import { useIntl } from "apps/freshbuffer/src/core/Intl";
import { NavLink } from "apps/freshbuffer/src/core/Routing/NavLink";
import { useRouter } from "next/router";
import React, { useState } from "react";
import styles from "./HeaderMobile.module.css";
import { LogoLink } from "./LogoLink";

export const HeaderMobile = () => {
  const router = useRouter();
  const { T, locale } = useIntl();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerCloseButtonClick = () => setIsDrawerOpen(false);
  const handleDrawerOpenButtonClick = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  return (
    <React.Fragment>
      <nav className={styles.root}>
        <IconButton onClick={handleDrawerOpenButtonClick}>
          <BurgerIcon color="secondary" />
        </IconButton>
        <LogoLink />
      </nav>
      <Drawer open={isDrawerOpen} anchor="left" onClose={handleDrawerClose}>
        <ul className={styles.drawer}>
          <li className={styles.drawerHead}>
            <IconButton onClick={handleDrawerCloseButtonClick}>
              <CrossIcon fontSize="small" color="secondary" />
            </IconButton>
          </li>
          <li>
            <NavLink.Anchor
              href={{ pathname: router.pathname, query: router.query }}
              locale={locale === "en" ? "nl" : "en"}
              underline="none"
            >
              {locale === "en" ? (
                <NLFlagIcon fontSize="small" />
              ) : (
                <ENFlagIcon fontSize="small" />
              )}
              {T("LBL_GLOBAL_LANGUAGE")}
            </NavLink.Anchor>
          </li>
          {/* <li className={styles.drawerDivider} />
          <li>
            <NavLink.Native underline="none">
              {T("HEADER_MENU_SERVICES")}
            </NavLink.Native>
          </li> */}
          <li className={styles.drawerDivider} />
          <li>
           <NavLink.Anchor
            href={ROUTES.ServiceNow.url({}, locale)}
            underline="none"
          >
            {T("HEADER_MENU_SERVICE_NOW")}
          </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
          <NavLink.Anchor
            href={ROUTES.AboutUs.url({}, locale)}

            underline="none"
          >
            {T("HEADER_MENU_ABOUTUS")}
          </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
            <NavLink.Anchor href="#" underline="none">
              {T("HEADER_MENU_CAREERS")}
            </NavLink.Anchor>
          </li>
          <li className={styles.drawerDivider} />
          <li>
          <nav>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={NavLink.Anchor}
          href={ROUTES.ContactUs.url({}, locale)}
        >
          {T("TALK_TO_US")}
        </Button>
      </nav>
          </li>
        
        </ul>
      </Drawer>
    </React.Fragment>
  );
};
