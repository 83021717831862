/* eslint-disable react/function-component-definition */
import React from 'react';

import getConfig from 'next/config';
import Head from 'next/head';

import { Typography, Container, ContainerProps } from '@mui/material';
import classNames from 'classnames';

import { CommonMessages } from 'apps/freshbuffer/src/core/CommonMessages';
import { useIntl } from 'apps/freshbuffer/src/core/Intl';
import { Locale } from 'apps/freshbuffer/src/core/Intl/types';
import { AppRoute, AppRouteParams } from 'apps/freshbuffer/src/core/Routing';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { LoadingIndicator } from './components/LoadingIndicator';
import styles from './PageLayout.module.css';

const { publicRuntimeConfig } = getConfig();

export interface PageLayoutProps {
  pageTitle: string;
  metaDescription?: string;
  route?: AppRoute<any>;
  routeParams?: AppRouteParams;
  nlRouteParams?: AppRouteParams;
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
  ogImage?: string;
  hideFooter?: boolean;
}

export const PageLayout = ({
  pageTitle,
  metaDescription,
  route,
  routeParams,
  nlRouteParams,
  loading = false,
  children,
  className,
  ogImage,
  hideFooter,
}: PageLayoutProps) => {
  const { T, locale } = useIntl();

  const title = React.useMemo(() => {
    return `${pageTitle.replace(
      // eslint-disable-next-line custom/no-restricted-translation-concatenation-with-template-literal
      ` | ${T(CommonMessages.app_name)}`,
      '',
      // eslint-disable-next-line custom/no-restricted-translation-concatenation-with-template-literal
    )}`;
  }, [T, pageTitle]);

  return (
    <React.Fragment>
      <Head>
        <title>{title}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {route && (
          <React.Fragment>
            {locale === Locale.en ? (
              <link
                rel="canonical"
                href={
                  publicRuntimeConfig.ORIGIN +
                  route.url({ ...routeParams }, Locale.en, 'prefix')
                }
              />
            ) : (
              <link
                rel="canonical"
                href={
                  publicRuntimeConfig.ORIGIN +
                  route.url({ ...nlRouteParams }, Locale.nl, 'prefix')
                }
              />
            )}
            <link
              rel="alternate"
              hrefLang="x-default"
              href={
                publicRuntimeConfig.ORIGIN +
                route.url({ ...routeParams }, Locale.en, 'prefix')
              }
            />
            <link
              rel="alternate"
              hrefLang={Locale.en}
              href={
                publicRuntimeConfig.ORIGIN +
                route.url({ ...routeParams }, Locale.en, 'prefix')
              }
            />
            <link
              rel="alternate"
              hrefLang={Locale.nl}
              href={
                publicRuntimeConfig.ORIGIN +
                route.url({ ...nlRouteParams }, Locale.nl, 'prefix')
              }
            />
          </React.Fragment>
        )}
        {ogImage && <meta property="og:image" content={ogImage} />}
      </Head>
      <div className={classNames(styles.root, className)}>
        <Header />

        <LoadingIndicator loading={loading} />

        {children}

        {!hideFooter && <Footer />}
      </div>
    </React.Fragment>
  );
};

PageLayout.MainContainer = function PageLayoutMainContainer({
  children,
  ...props
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'> &
  Pick<ContainerProps, 'maxWidth'>) {
  return (
    <Container
      id="page-content"
      data-testid="PageContent"
      component="main"
      {...props}
    >
      {children}
    </Container>
  );
};

PageLayout.MainRaw = function PageLayoutMainRaw({
  children,
  className,
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) {
  return (
    <main id="page-content" data-testid="PageContent" className={className}>
      {children}
    </main>
  );
};

PageLayout.SectionRaw = function PageLayoutSectionRaw({
  id,
  'data-testid': testid,
  className,
  children,
}: Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'id' | 'className' | 'children'
> & {
  'data-testid'?: string;
}) {
  return (
    <section id={id} data-testid={testid} className={className}>
      {children}
    </section>
  );
};

PageLayout.SectionContainer = function PageLayoutSectionContainer({
  id,
  'data-testid': testid,
  className,
  containerClassName,
  children,
}: Pick<
  React.HTMLAttributes<HTMLDivElement>,
  'id' | 'className' | 'children'
> & {
  'data-testid'?: string;
  containerClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
}) {
  return (
    <section id={id} data-testid={testid} className={className}>
      <Container className={containerClassName}>{children}</Container>
    </section>
  );
};

PageLayout.Title = function PageLayoutTitle({
  children,
  className,
}: Pick<React.HTMLAttributes<HTMLDivElement>, 'children' | 'className'>) {
  return (
    <Typography
      variant="h2"
      data-testid="PageTitle"
      className={classNames(styles.pageTitle, className)}
    >
      {children}
    </Typography>
  );
};
