import React from 'react';
import { useMediaIsDesktop } from 'apps/freshbuffer/src/utilities/Media/useMediaIsDesktop';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';
import styles from './Header.module.css';

export const Header = () => {
  const isDesktop = useMediaIsDesktop();

  return (
    <header id="page-header" data-testid="PageHeader" className={styles.root}>
      {isDesktop ? (
        <HeaderDesktop />
      ) : (
        <HeaderMobile />
      )}
    </header>
  );
};
