import Logo from 'apps/freshbuffer/src/assets/main-logo-transparent.png';
import { useIntl } from 'apps/freshbuffer/src/core/Intl';
import { ROUTES } from 'apps/freshbuffer/src/core/Routing';
import { NavLink } from 'apps/freshbuffer/src/core/Routing/NavLink';
import Image from 'next/legacy/image';
import styles from './LogoLink.module.css';

export const LogoLink = () => {
  const { locale } = useIntl();

  return (
    <NavLink.Anchor href={ROUTES.Home.url({}, locale)} className={styles.root}>
    <Image src={Logo.src} width={200} height={200} alt="Freshbuffer" />
    </NavLink.Anchor>
  );
};
